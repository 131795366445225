<template>
	<template v-if="currentSchoolyear && adminSchoolyear">
		<el-card shadow="hover">
			<el-row align="middle">
				<el-col :span="6">
					<div class="text-left">
						<h3>{{ $t('studentsList') }}</h3>
					</div>
				</el-col>
				<el-col :span="18">
					<div class="text-right">
						<el-button
							type="warning"
							size="small"
							:disabled="isCurrentDataLoading"
							icon="upload"
							class="mr-5"
							@click="launchExportNotes()">
							{{ $t('exportNotes') }}
						</el-button>
						<el-button
							type="success"
							size="small"
							:disabled="!multipleSelection.length || isCurrentDataLoading"
							icon="printer"
							class="mr-5"
							@click="launchPrintReports()">
							{{ $t('printReports') }}
						</el-button>
						<el-button
							type="primary"
							size="small"
							:disabled="!multipleSelection.length || isCurrentDataLoading"
							icon="document"
							class="mr-10"
							@click="launchDownloadNotes()">
							{{ $t('downloadNotes') }}
						</el-button>
						<el-select
							filterable
							size="small"
							v-model="currentClass"
							:placeholder="$t('select')"
							:no-data-text="$t('noData')"
							:no-match-text="$t('noMatchingData')"
							style="width: 250px">
							<el-option
								v-for="item in classes"
								:key="item.id"
								:value="item.id"
								:label="getClassName(item.id)">
							</el-option>
						</el-select>
					</div>
				</el-col>
			</el-row>

			<el-divider></el-divider>

			<el-table
				max-height="500"
				style="width: 100%"
				:data="pagedTableData"
				:empty-text="$t('noData')"
				:default-sort="{ prop: 'lastname', order: 'ascending' }"
				@selection-change="handleSelectionChange"
				border
				stripe
				v-loading="isLoading">
				<el-table-column type="selection" width="50"></el-table-column>
				<el-table-column type="index" :index="indexMethod" width="50" fixed>
				</el-table-column>
				<el-table-column
					sortable
					:label="$t('photo')"
					prop="photo"
					width="100"
					fixed>
					<template #default="scope">
						<el-avatar
							:src="scope.row.photo"
							v-if="scope.row.photo"></el-avatar>
						<el-avatar icon="avatar" v-else></el-avatar>
					</template>
				</el-table-column>
				<el-table-column
					sortable
					:label="$t('lastname')"
					prop="lastname"
					width="150"
					fixed
					show-overflow-tooltip>
				</el-table-column>
				<el-table-column
					sortable
					:label="$t('firstnames')"
					prop="firstname"
					width="150"
					fixed
					show-overflow-tooltip>
				</el-table-column>
				<el-table-column sortable :label="$t('sex')" prop="sex" width="150">
					<template #default="scope">
						<el-tag :type="scope.row.sex == Sexes.MALE.id ? '' : 'danger'">
							{{ getSexLabel(scope.row.sex) }}
						</el-tag>
					</template>
				</el-table-column>
				<el-table-column
					sortable
					:label="$t('parent')"
					prop="parent"
					width="150">
					<template #default="scope">
						{{ getParentName(scope.row.parent) }}
					</template>
				</el-table-column>
				<el-table-column sortable :label="$t('class')" prop="class" width="150">
					<template #default="scope">
						{{ getClassName(scope.row.class) }}
					</template>
				</el-table-column>
				<el-table-column
					sortable
					:label="$t('birthday')"
					prop="birthday"
					width="180">
					<template #default="scope">
						{{ scope.row.birthday ? reformatDate(scope.row.birthday) : '' }}
					</template>
				</el-table-column>
				<el-table-column
					sortable
					:label="$t('birthplace')"
					prop="birthplace"
					width="180">
				</el-table-column>
				<el-table-column
					sortable
					:label="$t('nationality')"
					prop="nationality"
					width="150">
				</el-table-column>
				<el-table-column
					sortable
					:label="$t('idNumber')"
					prop="idnum"
					width="150">
				</el-table-column>

				<el-table-column align="right" width="260" fixed="right">
					<template #header>
						<el-input
							v-model="search"
							size="small"
							:placeholder="$t('search')" />
					</template>

					<template #default="scope">
						<el-button
							size="small"
							type="danger"
							@click="viewDetails(scope.$index, scope.row)">
							{{ $t('details') }}
						</el-button>
						<el-button
							size="small"
							type="success"
							@click="viewNotes(scope.$index, scope.row)">
							{{ $t('viewNotes') }}
						</el-button>
					</template>
				</el-table-column>
			</el-table>

			<div style="text-align: center; margin-top: 20px">
				<el-pagination
					background
					layout="prev, pager, next"
					@current-change="setPage"
					:hide-on-single-page="true"
					:page-size="pageSize"
					:total="total">
				</el-pagination>
			</div>

			<div class="text-right mt-5" v-if="multipleSelection.length">
				<small v-if="multipleSelection.length > 1"
					><b>{{
						$t('xStudentsSelected', [multipleSelection.length])
					}}</b></small
				>
				<small v-else
					><b>{{ $t('oneStudentSelected') }}</b></small
				>
			</div>
		</el-card>

		<el-dialog
			width="35%"
			v-model="yearCuttingDialog"
			:title="isYearCuttingTerm ? $t('selectTrimester') : $t('selectSemester')">
			<div class="text-center">
				<el-select
					filterable
					v-model="currentYearCutting"
					:no-data-text="$t('noData')"
					:no-match-text="$t('noMatchingData')"
					:placeholder="
						isYearCuttingTerm ? $t('selectTrimester') : $t('selectSemester')
					"
					@change="updateDefaultYearCutting(currentYearCutting)"
					style="width: 80%"
					class="mb-5">
					<el-option
						v-for="item in yearCuttings"
						:key="item.id"
						:label="getYearCuttingName(item.id)"
						:value="item.id">
					</el-option>
				</el-select>

				<el-select
					multiple
					filterable
					:no-data-text="$t('noData')"
					:placeholder="$t('selectCourses')"
					:no-match-text="$t('noMatchingData')"
					@change="updateClassCoursesInactivity()"
					style="width: 80%; text-align: left"
					v-model="selectedClassCourses"
					v-show="yearCuttingDialogSrc == 'notes'"
					class="mt-10">
					<el-option
						v-for="item in classCourses"
						:label="
							item.course == '*' ? $t('allCourses') : getCourseName(item.course)
						"
						:key="item.course"
						:value="item.course"
						:disabled="!!item.inactive">
					</el-option>
				</el-select>
			</div>

			<template #footer>
				<span class="dialog-footer">
					<el-button @click="yearCuttingDialog = false">{{
						$t('cancel')
					}}</el-button>
					<el-button
						type="primary"
						@click="yearCuttingDialogSrc == 'notes' ? notesToPDF() : print()"
						>{{ $t('confirm') }}</el-button
					>
				</span>
			</template>
		</el-dialog>

		<el-dialog
			:destroy-on-close="true"
			:title="
				$t('details') +
				(currentDetailsStudent
					? ' - ' +
					  currentDetailsStudent.lastname +
					  ' ' +
					  currentDetailsStudent.firstname
					: '')
			"
			v-model="detailsDialog"
			width="50%"
			top="2vh">
			<view-details
				:student="currentDetailsStudent"
				:activity-list="activities"></view-details>

			<template #footer>
				<span class="dialog-footer">
					<el-button @click="detailsDialog = false">{{
						$t('close')
					}}</el-button>
				</span>
			</template>
		</el-dialog>

		<el-dialog
			width="30%"
			v-model="exportNotesDialog"
			:title="$t('exportNotes')">
			<div class="text-center">
				<el-select
					filterable
					v-model="currentYearCutting"
					:no-data-text="$t('noData')"
					:no-match-text="$t('noMatchingData')"
					:placeholder="
						isYearCuttingTerm ? $t('selectTrimester') : $t('selectSemester')
					"
					@change="updateDefaultYearCutting(currentYearCutting)"
					style="width: 80%"
					class="mb-5">
					<el-option
						v-for="item in yearCuttings"
						:key="item.id"
						:label="getYearCuttingName(item.id)"
						:value="item.id">
					</el-option>
				</el-select>
			</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="exportNotesDialog = false">{{
						$t('cancel')
					}}</el-button>
					<el-button @click="exportNotes()" type="primary">{{
						$t('confirm')
					}}</el-button>
				</span>
			</template>
		</el-dialog>

		<report-template
			:year-cutting="yearCuttings.find((y) => y.id == currentYearCutting)"
			:student-class="classes.find((c) => c.id == currentClass)"
			:student-activities="reportActivities"
			:class-size="tableData.length"
			:student="reportStudent"
			:verdict="reportVerdict"
			id="report"
			style="visibility: hidden" />
	</template>

	<el-alert :title="$t('noCurrentSchoolyearWarning')" type="warning" v-else />
</template>

<script>
	import { mapState, mapGetters, mapActions } from 'vuex'

	import fr from 'element-plus/lib/locale/lang/fr'

	import { Sexes, Routes } from '@/utils/enums'
	import { reformatDate } from '@/utils/chronos'

	import accessManager from '@/mixins/access-manager'
	import currentData from '@/mixins/current-data'

	import jsPDF from 'jspdf'
	import 'jspdf-autotable'

	import ViewDetails from './ViewDetails'
	import ReportTemplate from './ReportTemplate/index.vue'

	export default {
		name: 'ReportManage',
		mixins: [accessManager, currentData],
		components: {
			ViewDetails,
			ReportTemplate,
		},
		data() {
			return {
				Sexes,
				sexes: [],
				currentClass: null,
				reportStudent: null,
				reportVerdict: null,
				reportActivities: [],
				currentYearCutting: null,
				selectedClassCourses: [],
				yearCuttingDialogSrc: '',
				yearCuttingDialog: false,
				exportNotesDialog: false,
				detailsDialog: false,
				currentDetailsStudent: null,
				primaryColor: process.env.VUE_APP_PRIMARY_COLOR,
				classChoiceCourses: [],
				multipleSelection: [],
				evaluationNotes: [],
				evaluationTexts: [],
				isLoading: false,
				classCourses: [],
				evaluations: [],
				notesData: [],
				pageSize: 50,
				locale: fr,
				search: '',
				total: 0,
				page: 1,
			}
		},
		created() {
			var self = this

			this.$_.forIn(Sexes, function (value, key) {
				self.sexes.push(value)
			})

			this.currentYearCutting = this.defaultYearCutting
		},
		watch: {
			classes: {
				handler(val, oldVal) {
					if (!oldVal.length && val.length)
						this.currentClass = this.classes[0].id
				},
			},
		},
		computed: {
			...mapState({
				userData: (state) => state.session.userData,
			}),
			...mapGetters({
				getSettingValue: 'settings/getSettingValue',
			}),
			pagedTableData() {
				this.total = this.searching.length

				return this.searching.slice(
					this.pageSize * this.page - this.pageSize,
					this.pageSize * this.page
				)
			},
			searching() {
				if (!this.search) {
					this.total = this.tableData.length
					return this.tableData
				}

				this.page = 1

				return this.tableData.filter(
					(data) =>
						data.lastname.toLowerCase().includes(this.search.toLowerCase()) ||
						data.firstname.toLowerCase().includes(this.search.toLowerCase())
				)
			},
			tableData() {
				if (!this.adminSchoolyear || !this.currentClass) return []

				return this.$_.sortBy(
					this.students.filter((s) => s.class == this.currentClass),
					'lastname'
				)
			},
			activeEvaluations() {
				return this.$_.sortBy(
					this.evaluations.filter(
						(e) =>
							parseInt(e.display) && e.yearcutting == this.currentYearCutting
					),
					'type'
				).reverse()
			},
		},
		methods: {
			...mapActions({
				fetchUserDB: 'user/fetchUserDB',
				fetchTeachersDB: 'teachers/fetchTeachersDB',
				fetchRepartitionDB: 'repartition/fetchRepartitionDB',
				fetchEvaluationsDB: 'evaluations/fetchEvaluationsDB',
				fetchChoiceCoursesDB: 'choicecourses/fetchChoiceCoursesDB',
				fetchEvaluationNotesDB: 'evaluationnotes/fetchEvaluationNotesDB',
				fetchEvaluationTextsDB: 'evaluationtexts/fetchEvaluationTextsDB',
				fetchChosenActivitiesDB: 'chosenactivities/fetchChosenActivitiesDB',
				fetchEvaluationVerdictDB: 'evaluationverdict/fetchEvaluationVerdictDB',
			}),
			reformatDate,
			setPage(val) {
				this.page = val
			},
			indexMethod(index) {
				return index + (this.page - 1) * this.pageSize + 1
			},
			getSexLabel(id) {
				let sex = this.sexes.find((s) => s.id == id)

				return sex ? sex.label : ''
			},
			updateClassCoursesInactivity() {
				this.classCourses.forEach((classCourse, i) => {
					if (!this.selectedClassCourses.length) {
						this.classCourses[i].inactive = false
					} else {
						this.classCourses[i].inactive =
							classCourse.id === '*'
								? !this.selectedClassCourses.includes('*')
								: this.selectedClassCourses.includes('*')
					}
				})
			},
			viewNotes(index, item) {
				this.$router.push({
					name: 'Marks' + Routes.REPORT_MANAGE.NAME,
					params: {
						student: item.id,
						class: item.class,
					},
				})
			},
			viewDetails(index, item) {
				this.currentDetailsStudent = item

				this.detailsDialog = true
			},
			handleSelectionChange(val) {
				this.multipleSelection = val
			},
			async fetchData() {
				if (this.currentClass) {
					let resp0 = await this.fetchChoiceCoursesDB({
						action: 'getClassCourses',
						params: {
							class: this.currentClass,
							schoolyear: this.adminSchoolyear,
						},
					})

					if (resp0.success && resp0.data) this.classChoiceCourses = resp0.data

					let resp1 = await this.fetchRepartitionDB({
						action: 'getClassCourses',
						params: {
							class: this.currentClass,
							schoolyear: this.adminSchoolyear,
						},
					})

					if (resp1.success && resp1.data) {
						this.classCourses = []

						this.classCourses.push({
							class: '*',
							coef: '*',
							course: '*',
							id: '*',
							schoolyear: '*',
							teacher: '*',
						})

						this.classCourses.push(...resp1.data)

						this.selectedClassCourses = ['*']
						this.updateClassCoursesInactivity()
					}

					let resp2 = await this.fetchEvaluationsDB({
						action: 'getClassEvaluations',
						params: {
							class: this.currentClass,
							schoolyear: this.adminSchoolyear,
						},
					})

					if (resp2.success && resp2.data) this.evaluations = resp2.data

					let resp = await this.fetchEvaluationNotesDB({
						action: 'getClassEvaluationNotes',
						params: {
							class: this.currentClass,
							schoolyear: this.adminSchoolyear,
						},
					})

					if (resp.success && resp.data) this.evaluationNotes = resp.data

					let resp_ = await this.fetchEvaluationTextsDB({
						action: 'getClassEvaluationTexts',
						params: {
							class: this.currentClass,
							schoolyear: this.adminSchoolyear,
						},
					})

					if (resp_.success && resp_.data) this.evaluationTexts = resp_.data

					this.notesData = []

					this.$_.sortBy(this.multipleSelection, 'lastname').forEach(
						(student) => {
							let data = {
								id: student.id,
								lastname: student.lastname,
								firstname: student.firstname,
							}

							if (this.currentYearCutting) {
								this.evaluationNotes.forEach((ev) => {
									if (
										ev.yearcutting == this.currentYearCutting &&
										ev.student == student.id
									) {
										data['note_' + ev.evaluation] = parseFloat(ev.note)
										data['note_' + ev.evaluation + '_id'] = ev.id
									}
								})

								this.evaluationTexts.forEach((ev) => {
									if (
										ev.yearcutting == this.currentYearCutting &&
										ev.student == student.id
									) {
										data['attitude'] = ev.attitude
										data['progression'] = ev.progression
										data['advice'] = ev.advice
									}
								})
							}

							this.notesData.push(data)
						}
					)
				}
			},
			async launchPrintReports() {
				await this.fetchData()

				this.yearCuttingDialogSrc = 'reports'

				this.yearCuttingDialog = true
			},
			async launchDownloadNotes() {
				await this.fetchData()

				this.yearCuttingDialogSrc = 'notes'

				this.yearCuttingDialog = true
			},
			async launchExportNotes() {
				this.exportNotesDialog = true
			},
			async exportNotes() {
				if (this.isCurrentDataLoading) {
					this.$message.warning(this.$t('reportDataUnavailableMessage'))

					return
				}

				let mes = this.$message({
					message: this.$t('pleaseWait...'),
					duration: 0,
				})

				let teachers = []

				let resp0 = await this.fetchTeachersDB({
					action: 'getTeachers',
					params: {
						schoolyear: this.currentSchoolyear,
					},
				})

				if (resp0.success && resp0.data) teachers = resp0.data

				for (let i = 0; i < teachers.length; i++) {
					let teacher = teachers[i],
						teacherCourses = []

					let resp1 = await this.fetchRepartitionDB({
						action: 'getTeacherCourses',
						params: {
							teacher: teacher.id || 0,
							schoolyear: this.currentSchoolyear,
						},
					})

					if (resp1.success && resp1.data) teacherCourses = resp1.data

					for (let j = 0; j < teacherCourses.length; j++) {
						let teacherCourse = teacherCourses[j],
							currentClass = null

						let resp2 = await this.fetchClassesDB({
							action: 'getClass',
							params: {
								id: teacherCourse.class,
							},
						})

						if (resp2.success && resp2.data) currentClass = resp2.data

						if (currentClass) {
							let evaluations = [],
								classChoiceCourses = [],
								classStudents = []

							let resp3 = await this.fetchEvaluationsDB({
								action: 'getClassEvaluations',
								params: {
									class: currentClass.id,
									schoolyear: this.currentSchoolyear,
								},
							})

							if (resp3.success && resp3.data) evaluations = resp3.data

							let resp4 = await this.fetchChoiceCoursesDB({
								action: 'getClassCourses',
								params: {
									class: currentClass.id,
									schoolyear: this.currentSchoolyear,
								},
							})

							if (resp4.success && resp4.data) classChoiceCourses = resp4.data

							if (teacherCourse.class && teacherCourse.course) {
								let courseItems = classChoiceCourses.filter(
										(cc) => cc.course == teacherCourse.course
									),
									features = [],
									courseStudents = []

								courseItems.forEach((courseItem) => {
									courseStudents.push(courseItem.student)
									if (!features.includes(courseItem.feature))
										features.push(courseItem.feature)
								})

								if (features.length !== 1) {
									classStudents = this.$_.sortBy(
										this.students.filter((s) => s.class == currentClass.id),
										'lastname'
									)
								} else {
									classStudents = this.$_.sortBy(
										this.students.filter(
											(s) =>
												s.class == currentClass.id &&
												courseStudents.includes(s.id)
										),
										'lastname'
									)
								}

								let evaluationNotes = [],
									evaluationTexts = []
								let resp5 = await this.fetchEvaluationNotesDB({
									action: 'getEvaluationNotesBy',
									params: {
										class: teacherCourse.class,
										course: teacherCourse.course,
										schoolyear: this.currentSchoolyear,
									},
								})

								if (resp5.success && resp5.data) evaluationNotes = resp5.data

								let resp6 = await this.fetchEvaluationTextsDB({
									action: 'getEvaluationTextsBy',
									params: {
										class: teacherCourse.class,
										course: teacherCourse.course,
										schoolyear: this.currentSchoolyear,
									},
								})

								if (resp6.success && resp6.data) evaluationTexts = resp6.data

								let tableData = []

								classStudents.forEach((student) => {
									let data = {
										id: student.id,
										lastname: student.lastname,
										firstname: student.firstname,
									}

									if (this.currentYearCutting && teacher.id) {
										evaluationNotes.forEach((ev) => {
											if (
												ev.yearcutting == this.currentYearCutting &&
												ev.teacher == teacher.id &&
												ev.student == student.id
											) {
												data['note_' + ev.evaluation] = parseFloat(ev.note)
												data['note_' + ev.evaluation + '_id'] = ev.id
											}
										})

										evaluationTexts.forEach((ev) => {
											if (
												ev.yearcutting == this.currentYearCutting &&
												ev.teacher == teacher.id &&
												ev.student == student.id
											) {
												data['attitude'] = ev.attitude
												data['progression'] = ev.progression
												data['advice'] = ev.advice
											}
										})
									}

									tableData.push(data)
								})

								import('@/utils/export2excel').then((excel) => {
									var tHeader = [
											'ID',
											this.$t('lastname'),
											this.$t('firstnames'),
										],
										filterVal = ['id', 'lastname', 'firstname']

									const activeEvaluations = this.$_.sortBy(
										evaluations.filter(
											(e) =>
												parseInt(e.display) &&
												e.yearcutting == this.currentYearCutting
										),
										'type'
									).reverse()

									activeEvaluations.forEach((evaluation) => {
										tHeader.push(this.getEvaluationTypeName(evaluation.type))
										filterVal.push('note_' + evaluation.id)
									})

									tHeader.push(
										...[
											this.$t('attitude'),
											this.$t('progression'),
											this.$t('advices'),
										]
									)
									filterVal.push(...['attitude', 'progression', 'advice'])

									const list = this.$_.sortBy(tableData, 'lastname')
									const data = this.formatJson(filterVal, list)

									try {
										excel.export_json_to_excel({
											header: tHeader,
											data,
											filename: currentClass
												? this.getTeacherName(teacher.id) +
												  (' - ' + this.getClassName(currentClass.id)) +
												  (' - ' + this.getCourseName(teacherCourse.course)) +
												  (' - ' +
														this.getYearCuttingName(this.currentYearCutting))
												: '',
											autoWidth: true,
											bookType: 'xlsx',
										})
									} catch (error) {
										this.$message.error(this.$t('anErrorOccured'))
									}
								})
							}
						}
					}
				}

				mes.close()
			},
			notesToPDF() {
				if (this.isCurrentDataLoading) {
					this.$message.warning(this.$t('reportDataUnavailableMessage'))

					return
				}

				if (this.currentYearCutting && this.selectedClassCourses.length) {
					var tHeader = [this.$t('lastAndFirstNames')],
						filterVal = ['lastAndFirstName'],
						finalHeader = [],
						teachers = {},
						students = {},
						courses = {},
						coefs = {}

					var columnStyles = {
						lastAndFirstName: { halign: 'left', fontSize: 8, cellWidth: 25 },
					}

					let finalClassCourses =
						this.selectedClassCourses[0] == '*'
							? this.classCourses.filter(
									(cc) => !this.selectedClassCourses.includes(cc.course)
							  )
							: this.classCourses.filter((cc) =>
									this.selectedClassCourses.includes(cc.course)
							  )

					finalClassCourses.forEach((classCourse) => {
						tHeader.push(this.getCourseName(classCourse.course))
						filterVal.push('course_' + classCourse.course)

						teachers['course_' + classCourse.course] = classCourse.teacher
						courses['course_' + classCourse.course] = classCourse.course
						coefs['course_' + classCourse.course] = classCourse.coef

						columnStyles['course_' + classCourse.course] = {
							halign: 'center',
							valign: 'middle',
							minCellWidth: 12,
							minCellHeight: 15,
							fontSize: 7,
						}
					})

					tHeader.push(this.$t('result'))
					filterVal.push('result')
					columnStyles['result'] = {
						halign: 'center',
						valign: 'middle',
						minCellWidth: 12,
						minCellHeight: 15,
						fontSize: 7,
					}

					for (let i = 0; i < filterVal.length; i++) {
						finalHeader.push({
							header: tHeader[i],
							dataKey: filterVal[i],
						})
					}

					var tempList = this.$_.sortBy(this.notesData, 'lastname')
					for (let i = 0; i < tempList.length; i++) {
						tempList[i].lastAndFirstName =
							i +
							1 +
							' - \n\n' +
							tempList[i].lastname.trim() +
							'\n' +
							tempList[i].firstname.trim()
						students[i + 1] = tempList[i].id
					}

					const data = this.formatJson(filterVal, tempList)

					const doc = new jsPDF('landscape')

					var finalY = doc.lastAutoTable.finalY || 2

					doc.text(
						this.currentClass ? this.getClassName(this.currentClass) : '',
						14,
						finalY + 5
					)
					doc.setFontSize(11)
					doc.text(
						this.getYearCuttingName(this.currentYearCutting),
						14,
						finalY + 15
					)

					var self = this,
						result = {}

					doc.autoTable({
						body: data,
						theme: 'grid',
						startY: finalY + 20,
						columns: finalHeader,
						columnStyles: columnStyles,
						bodyStyles: {
							cellPadding: { top: 4, right: 2, bottom: 4, left: 2 },
						},
						headStyles: {
							halign: 'center',
							fillColor: [33, 150, 243],
							fontSize: 5,
						},
						horizontalPageBreakRepeat: 'lastAndFirstName',
						horizontalPageBreak: false,
						rowPageBreak: 'avoid',
						pageBreak: 'auto',
						margin: 2,
						didDrawCell: function (data) {
							if (
								data.column.index > 0 &&
								data.row.section === 'body' &&
								data.row.index > -1
							) {
								let labels = [],
									notes = [],
									lastI = 0

								for (let i = 0; i < self.activeEvaluations.length; i++) {
									let evaluation = self.activeEvaluations[i]

									let evNotes = self.evaluationNotes.filter(
										(ev) =>
											ev.student == students[data.row.index + 1] &&
											ev.teacher == teachers[data.column.dataKey] &&
											ev.course == courses[data.column.dataKey] &&
											ev.yearcutting == self.currentYearCutting &&
											ev.evaluation == evaluation.id &&
											ev.class == self.currentClass
									)

									let label =
										self
											.getEvaluationTypeCode(evaluation.type)
											.charAt(0)
											.toUpperCase() +
										(labels.filter((h) =>
											h.includes(
												self
													.getEvaluationTypeCode(evaluation.type)
													.charAt(0)
													.toUpperCase()
											)
										).length +
											1)

									labels.push(label)

									if (evNotes.length) {
										doc.text(
											label + ': ',
											data.cell.x + 1,
											data.cell.y + 5 * (i + 1)
										)
										doc.text(
											evNotes[0].note,
											data.cell.x + 6,
											data.cell.y + 5 * (i + 1)
										)

										notes.push(parseFloat(evNotes[0].note))
										lastI = i + 1
									}
								}

								if (notes.length) {
									let sum = 0,
										moy = 0

									notes.forEach((note) => {
										sum += note
									})

									moy = sum / notes.length

									doc.text(
										'M1: ',
										data.cell.x + 1,
										data.cell.y + 5 * (lastI + 1)
									)
									doc.text(
										String(moy),
										data.cell.x + 6,
										data.cell.y + 5 * (lastI + 1)
									)

									if (
										!self
											.getClassName(self.currentClass)
											.toLowerCase()
											.includes('premiere') &&
										!self
											.getClassName(self.currentClass)
											.toLowerCase()
											.includes('première') &&
										!self
											.getClassName(self.currentClass)
											.toLowerCase()
											.includes('terminale')
									) {
										doc.text(
											'coef: ',
											data.cell.x + 1,
											data.cell.y + 5 * (lastI + 2)
										)
										doc.text(
											coefs[data.column.dataKey],
											data.cell.x + 7,
											data.cell.y + 5 * (lastI + 2)
										)
									}

									if (result[data.row.index]) {
										result[data.row.index].push({
											moy: moy,
											coef: parseInt(coefs[data.column.dataKey]),
										})
									} else {
										result[data.row.index] = [
											{
												moy: moy,
												coef: parseInt(coefs[data.column.dataKey]),
											},
										]
									}
								}

								if (data.column.dataKey == 'result') {
									if (result[data.row.index]) {
										let fSumMoy = 0,
											fSumCoef = 0,
											fSumMoyCoef = 0,
											fMoy = 0

										result[data.row.index].forEach((data) => {
											fSumMoyCoef += data.moy * data.coef
											fSumCoef += data.coef
											fSumMoy += data.moy
										})

										fMoy = fSumMoyCoef / fSumCoef

										doc.text('N: ', data.cell.x + 1, data.cell.y + 5)
										doc.text(
											result[data.row.index].length +
												'/' +
												finalClassCourses.length,
											data.cell.x + 4,
											data.cell.y + 5
										)

										doc.text('T: ', data.cell.x + 1, data.cell.y + 10)
										doc.text(String(fSumMoy), data.cell.x + 4, data.cell.y + 10)

										if (
											!self
												.getClassName(self.currentClass)
												.toLowerCase()
												.includes('premiere') &&
											!self
												.getClassName(self.currentClass)
												.toLowerCase()
												.includes('première') &&
											!self
												.getClassName(self.currentClass)
												.toLowerCase()
												.includes('terminale')
										) {
											doc.text('M: ', data.cell.x + 1, data.cell.y + 15)
											doc.text(
												String(fMoy.toFixed(2)),
												data.cell.x + 4,
												data.cell.y + 15
											)
										}
									}
								}
							}
						},
					})

					doc.save(
						(this.currentClass
							? this.getClassName(this.currentClass) +
							  (' - ' + this.getYearCuttingName(this.currentYearCutting))
							: 'list') + '.pdf'
					)

					this.yearCuttingDialog = false
				}
			},
			async print() {
				if (this.isCurrentDataLoading) {
					this.$message.warning(this.$t('reportDataUnavailableMessage'))

					return
				}

				const report = document.getElementById('report')
				const students = this.$_.sortBy(this.multipleSelection, 'lastname')

				for (let i = 0; i < students.length; i++) {
					this.reportStudent = students[i]

					let resp = await this.fetchEvaluationVerdictDB({
						action: 'getStudentEvaluationVerdict',
						params: {
							student: this.reportStudent.id,
							class: this.reportStudent.class,
							yearcutting: this.currentYearCutting || 0,
							schoolyear: this.adminSchoolyear,
						},
					})

					if (resp.success && resp.data) this.reportVerdict = resp.data[0]
					else this.reportVerdict = null

					let resp_ = await this.fetchChosenActivitiesDB({
						action: 'getStudentChosenActivities',
						params: {
							student: this.reportStudent.id,
							schoolyear: this.adminSchoolyear,
						},
					})

					if (resp_.success && resp_.data) this.reportActivities = resp_.data
					else this.reportActivities = []

					const doc = new jsPDF({
						orientation: 'p',
						format: 'a4',
						unit: 'pt',
					})

					let dWidth = doc.internal.pageSize.width
					let srcWidth = report.scrollWidth
					let margin = 24

					let scale = (dWidth - margin * 2) / srcWidth

					var self = this

					doc.html(report, {
						callback: function (doc) {
							doc.autoPrint({ variant: 'non-conform' })
							doc.save(
								self.$t('report') +
									' - ' +
									self.getStudentName(students[i].id) +
									'.pdf'
							)
						},
						x: margin,
						y: margin,
						html2canvas: {
							scale: scale,
							onclone: function (clonedDoc) {
								clonedDoc.getElementById('report').style.visibility = 'visible'
							},
						},
					})
				}

				await this.fetchYearCuttingResults()

				this.yearCuttingDialog = false
			},
		},
	}
</script>
