<template>

    <div class="text-center mb-10">

        <div class="text-left mb-5" style="width: 80%; display: inline-block" v-if="currentYearCutting">

            <el-alert type="info" :title="$t('changesAutoSaved')" :closable="false" show-icon/>

        </div>

        <el-select
            filterable
            v-model="currentYearCutting"
            :no-data-text="$t('noData')"
            :no-match-text="$t('noMatchingData')"
            :placeholder="isYearCuttingTerm ? $t('selectTrimester') : $t('selectSemester')"
            @change="fetchData()"
            style="width: 80%">
            <el-option
                v-for="item in yearCuttings"
                :key="item.id"
                :label="getYearCuttingName(item.id)"
                :value="item.id">
            </el-option>
        </el-select>

    </div>

    <div class="text-center">

        <div v-if="isLoading" class="text-center" style="width: 80%; display: inline-block">
            <el-progress
                :stroke-width="2"
                :show-text="false"
                :percentage="100"
                :indeterminate="true"
                :duration="1"/>
        </div>

        <el-input
            rows="6"
            class="mb-5"
            type="textarea"
            style="width: 80%"
            v-model="verdict"
            :readonly="!currentYearCutting"
            :placeholder="$t('managementAppreciation')">
        </el-input>

        <el-input
            class="mb-5"
            style="width: 80%"
            v-model="conduct"
            :readonly="!currentYearCutting"
            :placeholder="$t('conduct')">
        </el-input>

        <el-row style="width: 80%; margin-left: 10%" class="mb-5">
            <el-col :span="8">
                <el-input
                    v-model="absence"
                    :readonly="!currentYearCutting"
                    :placeholder="$t('absences')">
                </el-input>
            </el-col>
            <el-col :span="8" style="padding-left: 3px; padding-right: 3px">
                <el-input
                    v-model="delay"
                    :readonly="!currentYearCutting"
                    :placeholder="$t('delays')">
                </el-input>
            </el-col>
            <el-col :span="8">
                <el-input
                    v-model="unjustified"
                    :readonly="!currentYearCutting"
                    :placeholder="$t('unjustified')">
                </el-input>
            </el-col>
        </el-row>

        <div style="width: 80%; margin-left: 10%; text-align: left">

            <el-checkbox v-model="congratulations" :disabled="!currentYearCutting" :true-label="1" :false-label="0">
                {{ $t('congratulations') }}
            </el-checkbox>
            <el-checkbox v-model="compliments" :disabled="!currentYearCutting" :true-label="1" :false-label="0">
                {{ $t('compliments') }}
            </el-checkbox>
            <el-checkbox v-model="encouragements" :disabled="!currentYearCutting" :true-label="1" :false-label="0">
                {{ $t('encouragements') }}
            </el-checkbox>
            <el-checkbox v-model="workAlert" :disabled="!currentYearCutting" :true-label="1" :false-label="0">
                {{ $t('workAlert') }}
            </el-checkbox>
            <el-checkbox v-model="conductAlert" :disabled="!currentYearCutting" :true-label="1" :false-label="0">
                {{ $t('conductAlert') }}
            </el-checkbox>

        </div>

        <div style="width: 80%; margin-left: 10%; border-top: 0.5px solid silver">
            <el-checkbox-group v-model="chosenActivities" size="default" style="text-align: left">
                <el-checkbox :label="item.id" :key="item.id" v-for="item in activityList" :disabled="!currentYearCutting">
                    {{ item.name }}
                </el-checkbox>
            </el-checkbox-group>
        </div>

    </div>

</template>

<script>
import { mapActions } from 'vuex'

import currentData from '@/mixins/current-data'

export default {
    mixins: [currentData],
    props: {
        student: {
            type: Object,
            required: true
        },
        activityList: {
            type: Array,
            default: []
        }
    },
    watch: {
        activityList: {
            handler(val, oldVal) {

                this.fetchData()

            }
        },
        changeData: {
            handler(val, oldVal) {

                if (this.currentYearCutting) this.updateVerdict()

            },
            deep: true
        },
        chosenActivities: {
            handler(val, oldVal) {

                if (this.currentYearCutting) this.updateChosenActivities()

            },
            deep: true
        }
    },
    created() {

        this.fetchData()

        this.currentYearCutting = this.defaultYearCutting

    },
    data() {
        return {
            primaryColor: process.env.VUE_APP_PRIMARY_COLOR,
            currentYearCutting: null,
            isLoading: false,
            verdict: null,
            delay: null,
            absence: null,
            conduct: null,
            unjustified: null,
            chosenActivities: [],
            congratulations: false,
            compliments: false,
            encouragements: false,
            workAlert: false,
            conductAlert: false
        }
    },
    computed: {
        changeData() {
            return [this.verdict, this.delay, this.absence, this.conduct, this.unjustified,
                    this.congratulations, this.compliments, this.encouragements, this.workAlert, this.conductAlert]
        }
    },
    methods: {
        ...mapActions({
            fetchEvaluationVerdictDB: 'evaluationverdict/fetchEvaluationVerdictDB',
            fetchChosenActivitiesDB: 'chosenactivities/fetchChosenActivitiesDB'
        }),
        async fetchData(){

            this.isLoading = true

            this.updateDefaultYearCutting(this.currentYearCutting)

            if (this.currentYearCutting) {

                let resp_ = await this.fetchChosenActivitiesDB({
                    action: 'getStudentChosenActivities',
                    params: {
                        student: this.student.id,
                        schoolyear: this.adminSchoolyear
                    }
                })

                if (resp_.success && resp_.data) {

                    let temp = []

                    resp_.data.forEach(chosenActivity => {

                        if (chosenActivity.value == 1 &&
                            chosenActivity.class == this.student.class &&
                            chosenActivity.yearcutting == this.currentYearCutting) {

                            temp.push(chosenActivity.activity)

                        }

                    })

                    this.chosenActivities = this.$_.cloneDeep(temp)

                }

            }

            let resp = await this.fetchEvaluationVerdictDB({
                action: 'getStudentEvaluationVerdict',
                params: {
                    student: this.student.id,
                    class: this.student.class,
                    yearcutting: this.currentYearCutting || 0,
                    schoolyear: this.adminSchoolyear
                }
            })

            if (resp.success && resp.data && resp.data.length) {

                this.delay = resp.data[0].delay
                this.verdict = resp.data[0].verdict
                this.conduct = resp.data[0].conduct
                this.absence = resp.data[0].absence
                this.unjustified = resp.data[0].unjustified

                this.congratulations = resp.data[0].congratulations && parseInt(resp.data[0].congratulations)
                this.compliments = resp.data[0].compliments && parseInt(resp.data[0].compliments)
                this.encouragements = resp.data[0].encouragements && parseInt(resp.data[0].encouragements)
                this.workAlert = resp.data[0].work_alert && parseInt(resp.data[0].work_alert)
                this.conductAlert = resp.data[0].conduct_alert && parseInt(resp.data[0].conduct_alert)

            } else {

                this.delay = ''
                this.verdict = ''
                this.conduct = ''
                this.absence = ''
                this.unjustified = ''

                this.congratulations = false
                this.compliments = false
                this.encouragements = false
                this.workAlert = false
                this.conductAlert = false

            }

            this.isLoading = false

        },
        async updateVerdict(){

            await this.fetchEvaluationVerdictDB({
                action: 'updateEvaluationVerdict',
                params: {
                    class: this.student.class,
                    yearcutting: this.currentYearCutting,
                    schoolyear: this.adminSchoolyear,
                    student: this.student.id,
                    verdict: this.verdict || '',
                    delay: this.delay || '',
                    absence: this.absence || '',
                    conduct: this.conduct || '',
                    unjustified: this.unjustified || '',
                    congratulations: this.congratulations ? 1 : 0,
                    compliments: this.compliments ? 1 : 0,
                    encouragements: this.encouragements ? 1 : 0,
                    work_alert: this.workAlert ? 1 : 0,
                    conduct_alert: this.conductAlert ? 1 : 0
                }
            })

        },
        async updateChosenActivities(){

            for (let i = 0; i < this.activityList.length; i++) {

                if (this.chosenActivities.includes(this.activityList[i].id)) {

                    await this.fetchChosenActivitiesDB({
                        action: 'updateChosenActivity',
                        params: {
                            class: this.student.class,
                            yearcutting: this.currentYearCutting,
                            student: this.student.id,
                            activity: this.activityList[i].id,
                            value: 1,
                            schoolyear: this.adminSchoolyear
                        }
                    })

                } else {

                    await this.fetchChosenActivitiesDB({
                        action: 'updateChosenActivity',
                        params: {
                            class: this.student.class,
                            yearcutting: this.currentYearCutting,
                            student: this.student.id,
                            activity: this.activityList[i].id,
                            value: 0,
                            schoolyear: this.adminSchoolyear
                        }
                    })

                }

            }

        }
    }
}
</script>